import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { FaTiktok } from "react-icons/fa";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { FaMapPin } from "react-icons/fa";
import Book from "../components/shared/Book";
import Heading from "../components/shared/Heading";
import {
	information,
	LOCATION_AMERICA,
	LOCATION_NIGERIA,
	LOCATIONS,
	sizes,
} from "../constant";
import { db } from "../firebase";
import { scrollToTop } from "../utils/scrollTop";
import { Helmet } from "react-helmet";
import Layout from "../components/shared/Layout";
const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const BookingsPage = () => {
	const [toggleBookBtn, setToggleBookBtn] = React.useState(false);
	const [isAccept, setIsAccept] = React.useState(false);
	const [isLoading, setIsloading] = React.useState(true);
	const [isDecline, setIsDecline] = React.useState(false);
	const [info, setInfo] = React.useState(true);
	const [policyService, setPolicyService] = React.useState({});
	const [location, setLocation] = React.useState("");
	const { country, state } = useParams();

	React.useEffect(() => {
		async function displayCountry() {
			setIsloading(true);

			const timeID = setTimeout(() => {
				setIsloading(false);

				if (country?.toUpperCase() === LOCATION_AMERICA) {
					db.collection("admin").onSnapshot((snapshot) => {
						const data = snapshot.docs.map((doc) => {
							return doc.data();
						});
						setPolicyService(data[0]);
					});

					db.collection("location").onSnapshot((snapshot) => {
						const data = snapshot.docs.map((doc) => {
							return doc.data();
						});
						setLocation(data[0].location);
					});
				}

				if (country?.toUpperCase() === LOCATION_NIGERIA) {
					db.collection("adminNG").onSnapshot((snapshot) => {
						// eslint-disable-next-line array-callback-return
						const data = snapshot.docs.map((doc) => {
							return doc.data();
						});
						setPolicyService(data[0]);
					});

					db.collection("location-NG").onSnapshot((snapshot) => {
						const data = snapshot.docs.map((doc) => {
							return doc.data();
						});
						setLocation(data[0].location);
					});
				}
			}, 5000);

			return () => clearTimeout(timeID);
		}

		displayCountry();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return (
			<div>
				<Helmet>
					<title>Bookings</title>
				</Helmet>
				<Layout>
					<div className="my-5 text-center text-orange-600">
						<p>Detecting Location...</p>
					</div>
				</Layout>
			</div>
		);
	}

	if (
		country?.toUpperCase() !== LOCATION_NIGERIA &&
		country?.toUpperCase() !== LOCATION_AMERICA
	) {
		return (
			<div>
				<Helmet>
					<title>Bookings</title>
				</Helmet>
				<Layout>
					<div className="my-5 text-xs font-semibold text-center text-orange-600 uppercase">
						<p>Services are currently unavailable in this region</p>
					</div>
				</Layout>
			</div>
		);
	}

	return (
		<div>
			<Helmet>
				<title>Bookings</title>
			</Helmet>
			<Layout>
				<div className="sticky top-[0px] left-0 right-0 z-20 w-full bg-black h-fit text-white font-thin  py-2 text-md">
					<div className="flex items-center justify-start ml-3 font-bold lg:justify-center lg:ml-0">
						<div>
							<p className="text-xs md:text-md">
								For your hair appointment, book now !
							</p>
							<p className="text-xs italic font-thin text-orange-300 md:text-md">
								You are accessing{" "}
								<span className="font-semibold">{`${state}, ${LOCATIONS[
									country.toUpperCase()
								]?.toLowerCase()}`}</span>{" "}
								branch
							</p>
						</div>
						{toggleBookBtn ? (
							<button
								onClick={() => {
									if (isAccept) {
										setInfo(false);
										setToggleBookBtn(false);
									}
								}}
								className="ml-2 text-white duration-300 ease-in rounded-full bg-slate-800 hover:bg-white hover:text-black">
								<MdOutlineKeyboardArrowUp size={30} />
							</button>
						) : (
							<button
								onClick={() => {
									if (isAccept) {
										setToggleBookBtn(true);
										scrollToTop(0, 0);
										setInfo(false);
									}
								}}
								className="ml-2 text-white duration-300 ease-in rounded-full bg-slate-800 hover:bg-white hover:text-black">
								<MdOutlineKeyboardArrowDown size={30} />
							</button>
						)}
					</div>
					<div className="absolute right-0 top-[25%] border-l-[1px] border-orange-800 pl-3">
						<div className="flex items-center text-orange-800 justify-evenly">
							<a
								href="https://www.tiktok.com/@username8251212?_t=8sCcvXUWuk2&_r=1"
								target="_blank"
								rel="noopener noreferrer">
								<FaTiktok
									size={15}
									className="mr-5 duration-300 ease-in hover:text-orange-300"
								/>
							</a>
						</div>
					</div>
				</div>

				{toggleBookBtn && (
					<div className="duration-300 ease-in opacity-1 h-fit">
						<Wrapper>
							<Elements stripe={promise}>
								<Book policyService={policyService} />
							</Elements>
						</Wrapper>
					</div>
				)}
				<div className="relative bg-white sm:mt-10 mb-20 w-[90%] mx-auto py-10 md:p-10 rounded-lg">
					<div
						className={`bg-white shadow-lg px-5 sm:pt-10  md:rounded-lg ease-in duration-300`}>
						<div className="border-b-[1px] border-orange-300 mb-5 pb-2 flex items-center justify-between">
							<Heading
								size={sizes.medium}
								children="Booking Policy"
								// isGreen={true}
								// isBlue={true}
								isGreyLight={true}
							/>
							<div className="flex flex-row text-orange-400">
								<FaMapPin size={20} />
								<span>{location}</span>
							</div>
						</div>
						<div className="">
							{policyService?.["policy"]?.map((child, idx) => (
								<p
									className="text-[16px] tracking-light font-[500] mb-2 "
									key={idx}>
									<span className="mr-5">&#x2022;</span> {child?.text || child}
								</p>
							))}
						</div>
						<div className="font-light text-sm border-t-[1px] border-orange-300 mt-5 pt-5">
							<span
								onClick={() => {
									if (!isAccept) {
										setIsAccept(true);
										setIsDecline(false);
										setInfo(true);
									}
								}}
								className={`${
									isAccept
										? "cursor-not-allowed text-neutral-400 font-thin"
										: "cursor-pointer text-orange-600 font-[400]"
								} border-r-[1px] pr-3 border-orange-600`}>
								Accept
							</span>
							<span
								onClick={() => {
									if (!isDecline) {
										setInfo(false);
										setIsDecline(true);
										setIsAccept(false);
										setToggleBookBtn(false);
									}
								}}
								className={`${
									isDecline
										? "cursor-not-allowed text-neutral-400 font-thin"
										: "cursor-pointer text-orange-600 font-[400]"
								}  pl-3 border-orange-600`}>
								Decline
							</span>
						</div>
						<div
							className={`text-orange-900 text-[11px] italic ease-in duration-300  ${
								isDecline ? "opacity-1 mt-3 pb-3" : "opacity-0 mt-6"
							}`}>
							<span>Routing back to home page...</span>
						</div>
						<div
							className={`text-orange-900 text-[11px] italic ease-in duration-300 flex items-center flex-col md:flex-row  ${
								isAccept && info ? "opacity-1 -mt-7 pb-3" : "opacity-0 -mt-4"
							}`}>
							<span>
								Continue your experience. Click the down button at the top or{" "}
							</span>
							<button
								onClick={() => {
									if (isAccept) {
										setToggleBookBtn(true);
										scrollToTop(0, 0);
										setInfo(false);
									}
								}}
								className="px-2 mt-2 ml-1 font-bold text-white bg-orange-300 rounded-full md:mt-0">
								click...
							</button>
						</div>
					</div>
					<div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-5 border-t-[1px] pt-5 mt-5 text-sm">
						{information.map((inform, idx) => (
							<div key={idx} className="px-5 pb-5 rounded-md shadow-lg">
								<div className="mt-8 mb-2 border-b-[1px]">
									<Heading isGreyLight={true} children={inform} />
								</div>
								<div
									className={`grid ${
										policyService?.[`${inform}`]?.length > 5 &&
										"md:grid-cols-2 gap-x-5"
									}`}>
									{policyService?.[`${inform}`]?.map((inf, idx) => (
										<div key={idx}>
											<p className="mb-1 font-thin text-neutral-600">
												<span className="mr-5 font-bold">&#x2022;</span>
												<span
													dangerouslySetInnerHTML={{
														__html: inf?.text || inf,
													}}
												/>
											</p>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</Layout>
		</div>
	);
};

const Wrapper = styled.section`
	form {
		width: 30vw;
		align-self: center;
		box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
			0px 2px 5px 0px rgba(50, 50, 93, 0.1),
			0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
		border-radius: 7px;
		padding-left: 10px;
		padding-right: 10px;
		/* padding-top: 30px; */
		padding-bottom: 30px;
	}

	input {
		border-radius: 6px;
		margin-bottom: 6px;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		max-height: 44px;
		font-size: 16px;
		width: 100%;
		background: white;
		box-sizing: border-box;
	}

	.result-message {
		line-height: 22px;
		font-size: 16px;
	}

	.result-message a {
		color: rgb(89, 111, 214);
		font-weight: 600;
		text-decoration: none;
	}

	.hidden {
		display: none;
	}

	#card-error {
		color: rgb(105, 115, 134);
		font-size: 16px;
		line-height: 20px;
		margin-top: 12px;
		text-align: center;
	}

	#card-element {
		border-radius: 4px 4px 0 0;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		max-height: 44px;
		width: 100%;
		background: white;
		box-sizing: border-box;
	}

	#payment-request-button {
		margin-bottom: 32px;
	}

	button:hover {
		filter: contrast(115%);
	}

	button:disabled {
		opacity: 0.5;
		cursor: default;
	}

	/* spinner/processing state, errors */
	.spinner,
	.spinner:before,
	.spinner:after {
		border-radius: 50%;
	}

	.spinner {
		color: #ffffff;
		font-size: 22px;
		text-indent: -99999px;
		margin: 0px auto;
		position: relative;
		width: 20px;
		height: 20px;
		box-shadow: inset 0 0 0 2px;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	}

	.spinner:before,
	.spinner:after {
		position: absolute;
		content: "";
	}

	.spinner:before {
		width: 10.4px;
		height: 20.4px;
		background: undefined;
		border-radius: 20.4px 0 0 20.4px;
		top: -0.2px;
		left: -0.2px;
		-webkit-transform-origin: 10.4px 10.2px;
		transform-origin: 10.4px 10.2px;
		-webkit-animation: loading 2s infinite ease 1.5s;
		animation: loading 2s infinite ease 1.5s;
	}

	.spinner:after {
		width: 10.4px;
		height: 10.2px;
		background: undefined;
		border-radius: 0 10.2px 10.2px 0;
		top: -0.1px;
		left: 10.2px;
		-webkit-transform-origin: 0px 10.2px;
		transform-origin: 0px 10.2px;
		-webkit-animation: loading 2s infinite ease;
		animation: loading 2s infinite ease;
	}

	@keyframes loading {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	@media only screen and (max-width: 600px) {
		form {
			width: 80vw;
		}
	}
`;

export default BookingsPage;
