/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import { GoAlert } from "react-icons/go";
import { RiVisaLine } from "react-icons/ri";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "react-datepicker/dist/react-datepicker.css";
import { SiAmericanexpress, SiMastercard } from "react-icons/si";

import { UserContext } from "../../context/user-context";
import { db } from "../../firebase";
import {
	REACT_APP_AUTHORIZED_ID_ONE,
	REACT_APP_BOOKLIMIT,
	REACT_APP_HTTPS_LINK,
	REACT_APP_SITE_URL,
	REACT_APP_STATUS,
} from "../../constant";
import { ValidateEmail } from "../../utils/ValidateEmail";
import Progressbar from "./Progressbar";
import { triggerProgress } from "../../utils/triggerProgress";
import removeHTMLTags from "../../utils/removeHTMLTags";
import DatePicker from "./DatePicker";
import FormGroupComponent from "./FormGroupComponent";
import { scrollToTop } from "../../utils/scrollTop";
import ServicesComponent from "./ServicesComponent";

function Book({ policyService }) {
	const dateNow = new Date();
	const elements = useElements();
	const stripe = useStripe();
	const { user } = useContext(UserContext);
	const [bookLimit, setBookLimit] = React.useState(false);
	const [noAvailability, setNoAvailability] = React.useState(false);
	const [lockToOneMonth, setLockToOneMonth] = React.useState(false);
	const [turnOffBookings, setTurnOffBookings] = React.useState(false);
	const [selectDate, setSelectDate] = React.useState(null);
	const [succeeded, setSucceeded] = React.useState(false);
	const [processing, setProcessing] = React.useState(false);
	const [emailError, setEmailError] = React.useState(false);
	const [_error, set_Error] = React.useState(null);
	const [disabled, setDisabled] = React.useState(true);
	const [clientSecret, setClientSecret] = React.useState("");
	// const [location, setLocation] = React.useState(null);
	const [allowproceed, setAllowProceed] = React.useState(false); //CHANGE BACK TO FALSE
	// const [permission, setPermission] = React.useState(false);
	const [authDate, setAuthDate] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [bookingData, setBookingData] = React.useState({});
	const [userEmail, setUserEmail] = React.useState("");
	const [timeleft, setTimeleft] = React.useState(0);
	const [dailyMax, setDailyMax] = React.useState(0);
	const [count, setCount] = React.useState(10);
	const [_timeSlot, setTimeSlot] = React.useState("");
	const [isPicked, setIsPicked] = React.useState("");
	// const [blackList, setBlackList] = React.useState([]);
	const [timeSlotArray, setTimeSlotArray] = React.useState([]);
	const orderNo = `PVG${Math.random().toString(36).slice(2)}`;
	const [timeSlotDB, setTimeSlotsDB] = React.useState([]);
	// const [blockedDatesDB, setBlockedDatesDB] = React.useState([]);
	const [selectedService, setSelectedService] = React.useState([]);
	const isReload = true;
	const status = REACT_APP_STATUS;

	React.useEffect(() => {
		db.collection("calendarmax").onSnapshot((snapshot) => {
			snapshot.docs.map((doc) => {
				setLockToOneMonth(doc.data()?.lockToOneMonth);
				setTurnOffBookings(doc.data()?.turnOffBookings);
			});
		});
		// db.collection("location").onSnapshot((snapshot) => {
		// 	const results = snapshot.docs.map((doc) => ({
		// 		data: doc.data(),
		// 	}));
		// 	setLocation(results[0].data.location);
		// });

		db.collection("timeSlot").onSnapshot((snapshot) => {
			const arr = [];
			snapshot.docs.map((doc) => {
				const day = doc.data().day;
				const time = doc.data().time;
				arr.push({ id: doc.id, day: day, time: time });
			});
			setTimeSlotsDB(arr);
		});

		// db.collection("block-dates").onSnapshot((snapshot) => {
		// 	const array = [];
		// 	snapshot.docs.map((doc) => {
		// 		array.push(new Date(doc.data().blockDate.seconds * 1000));
		// 	});
		// 	setBlockedDatesDB(array);
		// });

		// if (Notification.permission !== "granted") {
		// 	Notification.requestPermission().then((perm) => {
		// 		if (perm === "granted") {
		// 			setPermission(perm);
		// 		}
		// 	});
		// }

		// Blacklist
		// 		db.collection("blacklist").onSnapshot((snapshot) => {
		// 			const results = snapshot.docs.map((doc) => ({
		// 				data: doc.data(),
		// 				id: doc.id,
		// 			}));
		//
		// 			const arr = [];
		// 			results.map((doc) => {
		// 				const date = doc?.data?.date;
		// 				const twentyFourHours = 86400000;
		// 				const expiredBlockTime = date + twentyFourHours;
		//
		// 				//   if already expired
		// 				if (expiredBlockTime <= Date.now()) {
		// 					db.collection("blacklist")
		// 						.doc(doc?.id)
		// 						.delete()
		// 						.catch((error) => {
		// 							console.error("Error removing document: ", error);
		// 						});
		// 				} else {
		// 					arr.push(doc?.data?.email);
		// 				}
		// 			});
		// 			setBlackList(arr);
		// 		});
	}, []);
	const addOns = policyService["add on services"];
	const services = policyService["services"];
	const category = policyService["category"];
	// if (succeeded && permission) {
	// 	new Notification("Payment Notification", {
	// 		body: "Your Payment Was Successful!",
	// 		icon: logo,
	// 		tag: "payment notification",
	// 		requireInteraction: true,
	// 		lang: "en-US",
	// 	});
	// }
	const handleSelect = (date) => {
		if (Date.parse(date) <= new Date().setDate(new Date().getDate() + 1)) {
			return setAuthDate(true);
		}
		if (authDate) {
			setAuthDate(false);
		}
		if (error) {
			setError(false);
		}
		if (selectDate) {
			setSelectDate(null);
		}
		if (bookLimit) {
			setBookLimit(false);
		}

		if (date > dateNow) {
			db.collection("all-bookings").onSnapshot((snapshot) => {
				const results = snapshot.docs.map((doc) => ({
					data: doc.data(),
				}));

				const filterBydate = results?.filter(
					(booking) => booking?.data.date === date.toDateString()
				);
				const timeSlotArr = filterBydate?.map((time) => {
					if (time?.data?.timeSlot.includes("-block")) {
						return time?.data?.timeSlot.split("-")[0];
					} else {
						return time?.data?.timeSlot;
					}
				});
				setTimeSlotArray(timeSlotArr);

				let maxDailyBookingHour = 0;
				if (filterBydate?.length > 0) {
					for (let i = 0; i < filterBydate.length; i++) {
						maxDailyBookingHour += filterBydate?.[i]?.data?.duration;
					}
					if (
						maxDailyBookingHour < REACT_APP_BOOKLIMIT &&
						REACT_APP_BOOKLIMIT - maxDailyBookingHour >= 1
					) {
						setSelectDate(date.toDateString());
						setDailyMax(maxDailyBookingHour);
					} else {
						setBookLimit(true);
					}
				} else {
					setSelectDate(date.toDateString());
					setDailyMax(maxDailyBookingHour);
				}

				const _date = new Date(date);
				const selectedDay = _date.toLocaleString("en-US", { weekday: "short" });

				const actualDayTimeSlot = timeSlotDB.filter(
					(item) => selectedDay?.toLowerCase() === item?.day.toLowerCase()
				);

				setNoAvailability(
					actualDayTimeSlot[0].time.slice().sort().toString() ===
						timeSlotArr.slice().sort().toString()
				);
			});
		}
	};

	const handleSelectTimeSlot = (t) => {
		setTimeSlot(t);
		setIsPicked(t);
	};

	const handleOnChangeService = (key, value) => {
		scrollToTop(300, 600);
		setSelectedService([value]);
		setBookingData({ ...bookingData, [key]: value.text });
	};
	const handleOnChange = (e) => {
		setBookingData({ ...bookingData, [e.target.name]: e.target.value });
	};

	const handleChange = async (event) => {
		setDisabled(event.empty);
		set_Error(event.error ? event.error.message : "");
	};

	const cardStyle = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	// stripe percent logic
	const stripeDeduction = +(
		Number(process.env.REACT_APP_BOOKING_PRICE) *
		Number(process.env.REACT_APP_STRIPE_PERCENT)
	).toFixed(2);
	let totalBookingPrice = 0;
	let serviceDuration = 0;
	services?.filter((item) => {
		const text = removeHTMLTags(item?.text);
		if (text === bookingData?.service) {
			totalBookingPrice += Number(item?.price);
			serviceDuration += Number(item?.duration);
		}
	});
	addOns?.filter((item) => {
		const text = removeHTMLTags(item?.text);
		if (text === bookingData?.addOnServices) {
			totalBookingPrice += Number(item?.price);
		}
	});
	const totalDue =
		Number(process.env.REACT_APP_BOOKING_PRICE) + stripeDeduction;
	const bookingBalance =
		totalBookingPrice > totalDue
			? totalBookingPrice - Number(process.env.REACT_APP_BOOKING_PRICE)
			: Number(process.env.REACT_APP_BOOKING_PRICE) - totalBookingPrice;

	const createPaymentIntent = async () => {
		const price = totalDue;

		if (user) {
			try {
				const { data } = await axios.post(
					"/.netlify/functions/create-payment-intent-book",
					JSON.stringify({ price })
				);
				setClientSecret(data.clientSecret);
				setAllowProceed(true);
			} catch (error) {
				set_Error(error?.response?.data ? "Please contact PVG Admin..." : "");
			}
		} else if (userEmail) {
			if (ValidateEmail(userEmail)) {
				if (emailError) {
					setEmailError(false);
				}
				try {
					const { data } = await axios.post(
						"/.netlify/functions/create-payment-intent-book",
						JSON.stringify({ price })
					);
					setClientSecret(data.clientSecret);
					setAllowProceed(true);
				} catch (error) {
					set_Error(error?.response?.data ? "Please contact PVG Admin..." : "");
				}
			} else {
				setEmailError(true);
			}
		}
	};

	// Proceed function
	const handleSubmit = async (ev) => {
		ev.preventDefault();
		// One time payment
		setProcessing(true);

		if (clientSecret) {
			const payload = await stripe.confirmCardPayment(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement),
				},
			});

			if (payload.error) {
				set_Error(`Payment failed ${payload.error.message}`);
				setProcessing(false);
			} else {
				if (payload?.paymentIntent?.client_secret) {
					set_Error(null);
					setProcessing(false);
					setSucceeded(true);

					db.collection("all-bookings")
						.add({
							date: selectDate,
							customer: user?.displayName || userEmail,
							email: user?.email || userEmail,
							category: bookingData.category,
							service: bookingData.service,
							addOnServices: bookingData.addOnServices,
							orderNo: orderNo,
							quantity: 1,
							transactionDate: dateNow.toDateString(),
							payMethod: "Card",
							product: "PVG Appointment",
							sender: "PVG",
							total: `${process.env.REACT_APP_BOOKING_PRICE}.00`,
							balance: bookingBalance,
							duration: serviceDuration,
							status: status,
							timeSlot: _timeSlot,
						})
						.then(() => {
							console.log(`SUCCESSFULL`);
						})
						.catch((error) => console.log("Error" + error.message));

					setUserEmail("");
					setSelectDate("");
					setBookingData({});

					setTimeout(() => {
						SendClientSuccessfulPurchaseEmail();
						SendMerchantSuccessfulPurchaseEmail();
					}, 1000);
				}
			}
		}
	};

	const messageParams = {
		name: user?.displayName || userEmail,
		message: `Hi ${
			user?.displayName || userEmail
		}. Your hair appointment has been booked for ${selectDate}, ${_timeSlot}. Your details are as follow - Category: ${
			bookingData.category
		}, Services: ${bookingData.service}, Add-On-Service: ${
			bookingData.addOnServices
		}. Please ensure to come early. Thank you for your patronage!`,
		client: user?.email || userEmail,
		sender: "PVG",
		quantity: 1,
		product: "Hair Appointment",
		payMethod: "Card",
		total: `$${process.env.REACT_APP_BOOKING_PRICE}.00`,
		balance: `$${bookingBalance}`,
		orderNo: orderNo,
		date: dateNow.toDateString(),
		link: `${REACT_APP_SITE_URL}${orderNo}`,
		serviceCharge: `$${stripeDeduction}`,
		serviceDuration: `${serviceDuration} hours`,
		timeSlot: _timeSlot,
	};

	// eslint-disable-next-line no-unused-vars
	const SendClientSuccessfulPurchaseEmail = () => {
		emailjs
			.send(
				process.env.REACT_APP_EMAILJS_SERVICE,
				process.env.REACT_APP_EMAILJS_CUSTOMER_TEMPLATE,
				messageParams,
				process.env.REACT_APP_EMAILJS_API
			)
			.then((res) => {})
			.catch((err) => console.log(err));
	};

	const ManagementMessage = {
		name: "Management",
		message: `Hello. Hair appointment for ${
			user?.displayName || userEmail
		} has been booked for ${selectDate}, ${_timeSlot}. Here are the details of booking: Category: ${
			bookingData.category
		}, Services: ${bookingData.service}, add-On-Services: ${
			bookingData.addOnServices
		}. Customer's Email: ${user?.email || userEmail}`,
		client: REACT_APP_AUTHORIZED_ID_ONE,
		sender: "PVG Bookings",
		link: REACT_APP_HTTPS_LINK,
		customer: user?.displayName || user?.email || userEmail,
	};
	// eslint-disable-next-line no-unused-vars
	const SendMerchantSuccessfulPurchaseEmail = () => {
		emailjs
			.send(
				process.env.REACT_APP_EMAILJS_SERVICE,
				process.env.REACT_APP_EMAILJS_MERCHANT_TEMPLATE,
				ManagementMessage,
				process.env.REACT_APP_EMAILJS_API
			)
			.then((res) => {})
			.catch((err) => console.log(err));
	};

	React.useEffect(() => {
		if (succeeded) {
			triggerProgress(setTimeleft, setCount, isReload);
		}
	}, [succeeded, isReload]);

	const email = user?.email || userEmail;

	//  filter services option
	const filteredServices = services?.filter((service) => {
		if (REACT_APP_BOOKLIMIT - dailyMax >= 2) {
			return service;
		}
		if (REACT_APP_BOOKLIMIT - dailyMax < 2) {
			return service?.duration === 1.5;
		}
	});

	return (
		<div className="flex flex-col max-w-full mx-auto mt-10 md:p-5 bg-blur4 lg:flex-row width-[100%] justify-center">
			<div className="flex flex-col items-center lg:mx-auto lg:flex-1 lg-w-full">
				<div className="text-neutral-300 text-center text-2xl my-5 px-[10px]">
					<h2>
						For Your Hair Appointment, Schedule{" "}
						<span className="text-yellow-500">Now!</span>
					</h2>
				</div>
				<div className="lg:p-5 lg:pt-0 xl:pr-[20px] flex flex-col w-[90vw] items-center">
					<div className="w-[95vw] md:w-[70vw] xl:w-[50vw]">
						<ServicesComponent
							handleOnChange={handleOnChangeService}
							setSelectedService={setSelectedService}
							bookingData={bookingData}
							filteredServices={
								selectedService?.length > 0 ? selectedService : filteredServices
							}
						/>
					</div>
					<div className="flex flex-col items-center mx-auto md:mt-[-14px]">
						<div className="h-[350px] mb-5">
							<div className="xl:ml-0">
								<DatePicker
									lockToOneMonth={lockToOneMonth}
									turnOffBookings={turnOffBookings}
									handleSelect={handleSelect}
								/>
							</div>

							<div className="w-[332px]">
								{authDate ? (
									<div
										className={
											authDate
												? "w-full mt-[-12px] text-[10px] text-center text-orange-300 bg-orange-900 mb-0 px-[10px] pb-2 pt-5 opacity-1 ease-in duration-300"
												: "opacity-0"
										}>
										<span>BOOKINGS ARE NOT ALLOWED FOR THIS DAY</span>
									</div>
								) : (
									<div className="mt-[-12px]">
										{selectDate && !noAvailability && (
											<div className="w-[332px] mx-auto">
												<div className="pt-5 pb-2 select-date">
													<span>Date selected {selectDate}</span>
												</div>
											</div>
										)}
										{(bookLimit || noAvailability) && (
											<div className="w-[332px] mx-auto">
												<div className="pt-5 pb-2 select-date">
													<span>
														We are booked for this date - {selectDate}
													</span>
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
						{!noAvailability ? (
							<div className="flex justify-center mb-5">
								<label className="w-[90vw] md:w-[332px] mx-auto ">
									<p className="text-white">Email Address...</p>
									<input
										type="text"
										autoComplete="off"
										placeholder=" "
										name="email"
										id="email"
										value={userEmail}
										onChange={(e) => setUserEmail(e.target.value)}
										className={`rounded-none ${
											emailError
												? "border-[1px] border-orange-700 bg-orange-200"
												: "border-[0px]"
										}`}
									/>
								</label>
							</div>
						) : (
							<></>
						)}
					</div>
					{!noAvailability ? (
						<div className="flex flex-col mt-5 w-[70%] mx-auto">
							<FormGroupComponent
								user={user}
								email={email}
								authDate={authDate}
								selectDate={selectDate}
								bookingData={bookingData}
								category={category}
								addOns={addOns}
								timeSlotDB={timeSlotDB}
								timeSlotArray={timeSlotArray}
								handleSelectTimeSlot={handleSelectTimeSlot}
								isPicked={isPicked}
								dailyMax={dailyMax}
								error={error}
								_timeSlot={_timeSlot}
								setError={setError}
								handleOnChange={handleOnChange}
								createPaymentIntent={createPaymentIntent}
							/>
							<form
								className={
									allowproceed
										? "block ease-in duration-300 pt-2 w-[80vw]"
										: "hidden ease-in duration-300"
								}
								id="payment-form"
								onSubmit={handleSubmit}>
								{ValidateEmail(email) && (
									<div className="pb-5 rounded-lg bg-neutral-50">
										<div className="flex items-center justify-between ml-4">
											<div className="mr-10">
												<span className="text-[10px]">
													Due payment + service charge -{" "}
													<span className="text-green-700 text-[10px]">
														USD ${totalDue}
													</span>
												</span>
											</div>

											<div className="flex max-w-[95%] mb-1 items-center mx-auto justify-end mr-4 md:mr-6 lg:mr-5 xl:mr-6">
												<SiMastercard
													size={20}
													className="mr-3 text-yellow-500"
												/>
												<RiVisaLine size={30} className="mr-3 text-blue-900" />
												<div className="relative flex items-center mr-5">
													<RiVisaLine size={30} className="text-blue-800" />
													<span className="absolute bottom-[0px] right-[1px] text-[7px] italic">
														DEBIT
													</span>
												</div>
												<SiAmericanexpress
													size={20}
													className="mt-2 text-blue-600"
												/>
											</div>
										</div>

										<CardElement
											id="card-element"
											options={cardStyle}
											onChange={handleChange}
											className="w-[95%] mx-auto border-[1px] border-b-0 p-3 rounded-t-[4px] "
										/>
										<button
											className="bg-neutral-900 w-[95%] flex mx-auto justify-center py-2 rounded-b-md"
											disabled={processing || disabled || succeeded}
											id="submit">
											<span
												className={
													processing || disabled || succeeded
														? "text-neutral-50 font-light"
														: "text-orange-500 font-light"
												}>
												{processing ? (
													<div className="spinner" id="spinner"></div>
												) : (
													"PAY NOW"
												)}
											</span>
										</button>
									</div>
								)}
								{_error && (
									<div
										className="flex items-center justify-center text-xs text-orange-700 card-error "
										role="alert">
										<GoAlert className="mr-2" />
										{_error}
									</div>
								)}
								{succeeded && (
									<div className="w-[100%] mx-auto pt-1">
										<p className="my-2 text-xs font-light text-orange-300">
											Payment Succeeded.
											{email === "tisijola7@gmail.com" && (
												<span>
													{" "}
													see the result in your
													<a
														className="text-orange-500"
														href={`https://dashboard.stripe.com/test/payments`}
														target="_blank"
														rel="noreferrer">
														{" "}
														Stripe Dashboard.
													</a>
												</span>
											)}{" "}
											Page will refresh in {count}{" "}
											{count === 0 ? "second..." : "seconds..."}
										</p>
										<Progressbar value={timeleft} max={100} />
									</div>
								)}
							</form>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
}
export default Book;
