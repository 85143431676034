import React from "react";
import { REACT_APP_BOOKLIMIT } from "../../constant";
import Button from "./Button";

function FormGroupComponent({
	user,
	email,
	authDate,
	selectDate,
	bookingData,
	category,
	addOns,
	timeSlotDB,
	timeSlotArray,
	handleSelectTimeSlot,
	isPicked,
	dailyMax,
	error,
	_timeSlot,

	setError,
	handleOnChange,
	createPaymentIntent,
}) {
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (
					authDate ||
					!bookingData?.service ||
					!bookingData?.category ||
					!bookingData?.addOnServices ||
					(!selectDate && !email)
				) {
					setError(true);
				} else {
					createPaymentIntent();
				}
			}}
			className={`w-[95vw] md:w-[70vw] xl:w-[50vw] flex flex-col items-center pt-10 md:pt-0 ${
				user ? "mt-[-300px] lg:mt-[-25px]" : "mt-[-25px]"
			} `}>
			<div className="flex flex-col md:flex-row md:items-center md:justify-between w-[100%] sm:w-[70%] gap-2">
				<div className="mb-[20px] w-[100%]">
					<div className="text-xs font-light text-neutral-50">
						<label>Category</label>
					</div>
					<select
						className="w-full mt-1 select-category"
						onChange={handleOnChange}
						id="category"
						value={bookingData?.category}
						name="category">
						<option value="" disabled selected>
							Select category
						</option>
						{category?.map((category, idx) => (
							<option
								className="w-[00vw]"
								key={idx}
								dangerouslySetInnerHTML={{
									__html: category.text || category,
								}}
							/>
						))}
					</select>
				</div>
				<div className="mb-[20px] w-[100%]">
					<div className="text-xs font-light text-neutral-50">
						<label>Add-On Services</label>
					</div>
					<select
						className="w-full mt-1 select-category"
						onChange={handleOnChange}
						id="addOnServices"
						value={bookingData?.addOnServices}
						name="addOnServices">
						<option value="" disabled selected>
							Select add-on
						</option>
						{addOns?.map((ad, idx) => (
							<option
								key={idx}
								dangerouslySetInnerHTML={{
									__html: ad.text || ad,
								}}
							/>
						))}
					</select>
				</div>
			</div>
			<div className="mb-[20px] relative">
				<div className="mb-1 text-xs font-light text-neutral-50">
					<span>Time slot</span>
				</div>
				{selectDate ? (
					<div>
						{timeSlotDB.map(
							(item) =>
								selectDate.toLowerCase()?.includes(item?.day.toLowerCase()) && (
									<div key={item.id}>
										<p className="absolute top-0 text-xs text-white left-16">
											{item?.day}
										</p>
										<div className="flex flex-wrap text-xs text-black">
											{item?.time.map((slot, idx) => (
												<span
													onClick={() =>
														!timeSlotArray?.includes(slot) &&
														handleSelectTimeSlot(slot)
													}
													key={idx}
													className={`${
														timeSlotArray?.includes(slot) ||
														isPicked === slot ||
														slot.includes("-block")
															? "cursor-not-allowed text-neutral-600"
															: "bg-white  cursor-pointer md:hover:bg-neutral-600 md:hover:text-white "
													}  py-1 px-2 rounded-2xl mr-2 mb-2 duration-300 ease-in`}>
													{slot.split("-")[0]}
												</span>
											))}
										</div>
									</div>
								)
						)}
					</div>
				) : (
					<span className="text-[10px] font-thin text-neutral-500">
						Select date to display daily time slot...
					</span>
				)}
			</div>
			{REACT_APP_BOOKLIMIT - dailyMax < 2 && (
				<div className="text-orange-300 font-thin text-[11px] bg-neutral-800 p-2 rounded-lg">
					<span>
						<span className="text-sm">We are almost booked up!</span> <br />
						We can only accommodate services of one hour 30 minutes or less.
					</span>
				</div>
			)}
			<div>
				{error && (
					<span className="text-rose-500 text-[14px] w-[100%] mx-auto mb-10">
						Missing Credential: Date...
					</span>
				)}

				{/* {!blackList?.includes(email.toLowerCase()) ? (
									<div className="text-center mt-[30px]">
										{bookingData?.service &&
											bookingData?.category &&
											bookingData?.addOnServices &&
											selectDate &&
											_timeSlot &&
											(userEmail || user?.email) && (
												<Button type="submit" class="btn">
													Submit Booking
												</Button>
											)}
									</div>
								) : (
									<div className=" font-thin uppercase mt-2 text-[10.5px] bg-neutral-800 p-2 rounded-lg">
										<p className="text-orange-400 border-b-[0.5px] border-neutral-700 mb-2 pb-2">
											The user is not authorize for booking at this time.
											Contact PVG admin for any support.
										</p>
										<span className="text-neutral-400">
											Eligible for booking is after 24 hours of cancelation.
										</span>
									</div>
								)} */}
				<div className="text-center mt-[30px]">
					{bookingData?.service &&
						bookingData?.category &&
						bookingData?.addOnServices &&
						selectDate &&
						_timeSlot &&
						email && (
							<Button type="submit" class="btn">
								Submit Booking
							</Button>
						)}
				</div>
			</div>
		</form>
	);
}

export default FormGroupComponent;
